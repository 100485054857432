<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import {Link, useForm} from '@inertiajs/vue3';
import {useAppStore} from "@/Stores/app.js";
import {computed} from "vue";

const appStore = useAppStore();

const form = useForm({
    locale: appStore.page.props.locale,
});

const computedLanguages = computed(() => {
    return appStore.page.props.languages.map(language => {
        return {
            label: language.name + (!language.is_published ? ' (unpublished)' : ''),
            value: language.code,
        }
    });
});

const setLanguage = () => {
    form.put(route('language.update'), {
        preserveScroll: true,
    })
};
</script>

<template>
    <div class="guest">
        <div class="mb-6">
            <Link href="/">
                <ApplicationLogo class="w-auto h-12 text-gray-500" drive-color="#fff" iq-color="#fff"/>
            </Link>
        </div>

        <div
            class="w-full sm:max-w-lg px-6 py-6 bg-white shadow-md overflow-hidden sm:rounded-lg"
        >
            <slot/>
        </div>
        <div class="footer">
            <div class="copyright" v-html="__('global.copyright')"></div>
            <div class="language">
                <label class="sr-only" for="language">Language</label>
                <select class="language__dropdown" id="language" v-model="form.locale" @change="setLanguage">
                    <option :value="language.value" v-for="language in computedLanguages" v-html="language.label"></option>
                </select>
            </div>
        </div>
    </div>
</template>

<style>
.guest {
    @apply min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100 font-light antialiased tracking-tighter;
    background-color: #33898c;
    background: linear-gradient(180deg, rgba(51, 110, 115, 1) 0%, rgba(51, 110, 115, 1) 50%, rgba(51, 137, 140, 1) 90%, rgba(51, 137, 140, 1) 100%) !important;
}

.footer {
    @apply mt-4 flex justify-between items-center w-full max-w-lg px-4 sm:px-0 flex-col-reverse xs:flex-row gap-4;
}

.copyright {
    @apply text-white text-sm font-medium;
}

.language__dropdown {
    @apply rounded-full text-sm pl-2 pr-10 py-2;
}
</style>
